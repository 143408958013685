import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
    return(
        <React.Fragment>
            <div className="aboutUsCont">
                
            </div>
        </React.Fragment>
    );
};

export default AboutUs;